import React from "react";
import Slider from "infinite-react-carousel";
import "../../style/sponsors/slider.scss";

import ntpLogo from "../../assets/sponzori/ntp_logo.png";
import elfakLogo from "../../assets/sponzori/elfak_logo.png";
//import vegaLogo from "../../assets/sponzori/vegait.svg";
//import webelinxLogo from "../../assets/sponzori/webelinx.png";
//import cocaCola from "../../assets/sponzori/coca-cola.png";
// import sicefLogo from "../../assets/sponzori/sicef_logo.png";
// import rcmtLogo from "../../assets/sponzori/rcmt_logo.png";
// import diligentLogo from "../../assets/sponzori/diligent_logo.png"
// import BCLogo from "../../assets/sponzori/BC_logo.png"
// import IngLogo from "../../assets/sponzori/ing_logo.png"
// import MHGLogo from "../../assets/sponzori/MHG_logo.png"
// import nlhLogo from "../../assets/sponzori/nlh_logo.png"
// import productHiveLogo from "../../assets/sponzori/Product_Hive.png"
//import mvpWorkshopLogo from "../../assets/sponzori/MVP-Workshop-black-logo.png";
//import IngLogo from "../../assets/sponzori/ingsoftware.svg";
import DualSoftLogo from "../../assets/sponzori/Dual-Soft-Logo.png";
import HorisenLogo from "../../assets/sponzori/Horisen-Logo.png";
import SeavusLogo from "../../assets/sponzori/Seavus-logo.png"

class SimpleSlider extends React.Component {
  render() {
    const settings = {
      autoplay: true,
      autoplaySpeed: 500,
      centerPadding: 0,
      duration: 1500,
      overScan: 3,
      slidesToShow: 2,
      wheel: true,
      arrows: false,
      centerMode: true,
    };

    return (
      <div className="slider">
        <Slider {...settings}>
          <div className="slika-container">
            <a href="https://www.dualsoft.rs/" target="_blank">
              <img className="slika" alt="sponzor " src={DualSoftLogo}></img>
            </a>
          </div>
          <div className="slika-container">
            <a href="https://www.horisen.rs/en" target="_blank">
              <img className="slika" alt="sponzor " src={HorisenLogo}></img>
            </a>
          </div>
          <div className="slika-container">
            <a href="https://www.sedc.mk/sr/" target="_blank">
              <img className="slika" alt="sponzor " src={SeavusLogo}></img>
            </a>
          </div>
          {/*<div className="slika-container">
            <a href="https://webelinxgames.com/" target="_blank">
              <img className="slika" alt="sponzor " src={webelinxLogo}></img>
            </a>
          </div>
          <div className="slika-container">
            <a href="https://www.coca-cola.rs/">
              <img className="slika" alt="sponzor " src={cocaCola}></img>
            </a>
          </div>

          <div className="slika-container">
                        <a href="http://www.sicef.info">
                            <img className="slika" alt="sponzor " src={sicefLogo}></img></a>
                    </div>
                    <div className="slika-container">
                        <a href="https://www.rcmtiteurope.com">
                            <img className="slika" alt="sponzor " src={rcmtLogo}></img></a>
                    </div>
                    <div className="slika-container">
                        <a href="https://www.dilig.net/?fbclid=IwAR3dktFSmFB9Z456_aC6xxavc8ZRVjy-ByTRgJAFDTwafavCx7zdkw6Kzg8">
                            <img className="slika" alt="sponzor " src={diligentLogo}></img></a>
                    </div>
                    <div className="slika-container">
                        <a href="https://bettercollective.com/#gref">
                            <img className="slika" alt="sponzor " src={BCLogo}></img></a>
                    </div>
                    <div className="slika-container">
                        <a href="https://www.ingsoftware.com/">
                            <img className="slika" alt="sponzor " src={IngLogo}></img></a>
                    </div>
                    <div className="slika-container">
                        <a href="https://www.madheadgames.com/?fbclid=IwAR2d-yi73_Tsck_nIySUich2-xk-RP1vM3lS0ZzjJgJ4cTNZU4WQ8EYnrWA">
                            <img className="slika" alt="sponzor " src={MHGLogo}></img></a>
                    </div>
                    <div className="slika-container">
                        <a href="https://nolimithub.com">
                            <img className="slika" alt="sponzor " src={nlhLogo}></img></a>
                    </div>
                    <div className="slika-container">
                        <a href="https://producthive.io/?fbclid=IwAR2EbsAoYLNXMTQDNxki-OoTXs8abNmiQXpdAr6IAY22BWAxMr3EPZDPPgI">
                            <img className="slika" alt="sponzor " src={productHiveLogo}></img></a>
                    </div>*/}
        </Slider>
      </div>
    );
  }
}

export default SimpleSlider;
