import React from "react";
import "../../style/agenda/agenda-day.scss";

const AgendaDayOne = (props) => (
  <div className="agenda-day">
    <h1>Hardware engineering</h1>
    <h2>14:00</h2>
    <p>Svečano otvaranje prvog dana</p>
    <h2>14:30</h2>
    <p>Elektronske komponente - od primene do fizike <br></br> Miloš Marjanović, asistent @ Elektronski fakultet u Nišu</p>
    <h2>15:20</h2>
    <p>Detekcija objekata bazirana na mašinskom učenju <br></br> Jelena Nedeljković, saradnik u nastavi @ Elektronski fakultet u Nišu</p>
    <h2>16:10</h2>
    <p>Pauza</p>
    <h2>17:00</h2>
    <p>Lean 101: Moderan start up način u razvoju proizvoda Aleksandra Ristić, Naučno-tehnološki park Niš</p>
    <h2>17:50</h2>
    <p>Svečano zatvaranje prvog dana</p>
  </div>
);

export default AgendaDayOne;
