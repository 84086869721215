import React from "react"
import "../../style/lecturers/lecturers-list.scss"
import LecturersCard from "./lecturers-card"

import milos from "../../assets/predavaci/milos-marjanovic.jpg"
import bratislav from "../../assets/predavaci/bratislav-vukadinovic.jpg"
import nikola from "../../assets/predavaci/nikola-zlatkov.png"
import teodora from "../../assets/predavaci/teodora-gavrilovic.jpeg"
import aleksandar from "../../assets/predavaci/aleksandar-milosavljevic.jpeg"
import jelena from "../../assets/predavaci/jelena-nedeljkovic.png"
import aleksandra from "../../assets/predavaci/aleksandra-ristic.png"


class LecturersList extends React.Component {
    render() {
        return (
            <div className="lecturers-list">
                <div className="lecturers-list-row">
                    <LecturersCard slika={bratislav} ime="Bratislav Vukadinović" firma="Seavus IT Edukativni Centar">Završio elektronski fakultet, na smeru elektronika i telekomunikacija. Sertifikatiovan za: MS - MCSA, MCP, Server Virtualization with Windows Server Hyper-V and System Center, Azure Solutions Architect Expert, Cisco -  CCNA, CCNA Security, CCNP. U IT industriji je od 2001. godine. Poslednjih 15 godina radi u kompaniji Seavus, trenutno na poziciji Technical Lead za Cloud, DevOps and Ifrastructue. Nakon 12 godina rada u  IT departmentu prelazi  u Azure Cloud DevOps, u sklopu managed services departmenta.  Predavač je na DevOps akademiji Seavus IT edukativnog centra. Posvećen i svestran, pored IT industirije, profesionalno se bavi i muzikom. </LecturersCard>
                    <LecturersCard slika={nikola} ime="Nikola Zlatkov" firma="Dual SOFT">Nikola Zlatkov je iz Niša gde je završio Elektronski fakultet, smer Računarstvo i informatika. Kao student IV godine, započeo je praksu u DualSoft-u, a trenutno radi na poziciji Front-End developera. U kompaniji je dve godine.</LecturersCard>
                </div>
                <div className="lecturers-list-row">
                    <LecturersCard slika={jelena} ime="Jelena Nedeljković" firma="Elektronski Fakultet Niš">Jelena Nedeljković je asistent na katedri za elektroniku Elektronskog fakulteta u Nišu, gde je angažovana u držanju računskih i laboratorijskih vežbi na predmetima Mikrokontroleri, Računarske mreže, Bežične računarske mreže i uređaji, Sistemi za rad u realnom vremenu. Domen njenog dosadašnjeg istraživanja su Fault Tolerant Systems (Sistemi otporni na greške) kao i primena veštačke inteligencije u embedded sistemima.</LecturersCard>
                    <LecturersCard slika={aleksandar} ime="Prof. dr Aleksandar Milosavljević" firma="Elektronski Fakultet Niš">Aleksandar Milosavljević je redovni profesor na Katedri za računarstvo Elektronskog fakulteta u Nišu, gde je angažovan u držanju nastave na predmetima Softversko inženjerstvo, Interakcija čovek-računar, Računarski vid, Duboko učenje. Težište njegovog naučnog rada su integracija geografskih informacionih sistema i sistema za video nadzor, primena dubokog učenja za klasifikaciju slika za potrebe biomonitoringa, kao i semantička segmentacija aerofoto snimaka u cilju automatske obrade i ekstrakcije geoprostornih podataka.</LecturersCard>
                </div>
                <div className="lecturers-list-row">
                    <LecturersCard slika={teodora} ime="Teodora Gavrilović" firma="Master inženjer organizacionih nauka">Osnovne studije je završila na Fakultetu Organizacionih Nauka u Beogradu, a kasnije i master na temu "Uvođenje agilnog pristupa u upravlajnju projektima studentske organizacije". IT-evac po struci, menadžer po pozivu, trenutno radi kao Delivery manager. Kao aktivan volonter tokom studiranja, stekla je razne "soft" veštine.</LecturersCard>
                    <LecturersCard slika={aleksandra} ime="Aleksandra Ristić" firma="Naučno-tehnološki park Niš">Aleksandra Ristić, diplomirani ekonomista, master inženjer menadžmenta. Od 2019. godine radi sa early-stage startapovima i zalaže se za osnaživanje startap ekosistema u regionu. Trenutno je na poziciji savetnika za razvoj poslovanja u NTP Niš.</LecturersCard>
                </div>
                <div className="lecturers-list-row">
                    <LecturersCard slika={milos} ime="Miloš Marjanović" firma="Elektronski Fakultet Niš">Diplomirao 2013. god. na Elektronskom fakultetu u Nišu sa prosečnom ocenom 10.00. Student doktorskih studija na Elektronskom fakultetu u Nišu gde je zapošljen i izvodi vežbe iz 15 predmeta. Učestvovao na 3 projekta iz oblasti razvoja visokog obrazovanja. Autor je 58 radova. Priznanja: Povelja grada Niša 11 . januar (2012), Povelja za najboljeg studenta generacije 2008/09, Srebrni znak Univerziteta u Nišu (2012/13). Od 2013. potpredsednik konferencije IEEESTEC. Član je IEEE udruženja od 2010, od 2015. predsednik IEEE SB Niš, od 2021. sekretar ED/SSC društva IEEE S&M. Učestovavo u organizaciji međunarodnih konferencija MIEL i RAD. Držao više predavanja studentskim organizacijama BEST, EESTEC, SICEF. Osnivač i realizator Arduino radionica na Elektronskom fakultetu (od 2018). Učestvovao je u 5 projekta pod pokroviteljstvom CPN-a u NK Niš i Leskovac. Od 2021. član žirija takmičenja  „Galaksija kup“.</LecturersCard>
                </div>
            </div>
        )
    }
}

export default LecturersList;