import React from "react"
import "../../style/footer/footer-area.scss"

import faceImg from "../../assets/face.png"
import instaImg from "../../assets/insta.png"
import linkedinImg from "../../assets/linkedin.png"
import spotImg from "../../assets/spot.png"
import phoneImg from "../../assets/phone.png"
import mailImg from "../../assets/mail.png"

import ntpLogo from "../../assets/sponzori/ntp_logo.png"
import elfakLogo from "../../assets/sponzori/elfak_logo.png"
import sicefLogo from "../../assets/sponzori/sicef_logo.png"
import rcmtLogo from "../../assets/sponzori/rcmt_logo.png"
import diligentLogo from "../../assets/sponzori/diligent_logo.png"
import BCLogo from "../../assets/sponzori/BC_logo.png"
import IngLogo from "../../assets/sponzori/ing_logo.png"
import MHGLogo from "../../assets/sponzori/MHG_logo.png"
import nlhLogo from "../../assets/sponzori/nlh_logo.png"
import productHiveLogo from "../../assets/sponzori/Product_Hive.png"

class FooterArea extends React.Component {
  render() {
    return (
      <div className="footer-area" id="footer">
        <div className="container">
          <div className="footer-area-cards">
            <div className="card">
              <p className="card-naslov">Društvene mreže:</p>
              <div className="card-linija"></div>
              <div className="slike">
                <li>
                  <a href="https://www.facebook.com/TechnologyAndTrends/">
                    <img alt="mreza" className="mreza" src={faceImg}></img>
                  </a>
                  <a href="https://www.instagram.com/sicef.info/ ">
                    <img alt="mreza" className="mreza" src={instaImg}></img>
                  </a>
                  <a href="https://www.linkedin.com/company/sicef/">
                    <img alt="mreza" className="mreza" src={linkedinImg}></img>
                  </a>
                </li>
              </div>
            </div>
            <div className="card">
              <p className="card-naslov">Kancelarija:</p>
              <div className="card-linija"></div>
              <div className="drzac-slike-i-imena">
                <img alt="spot" src={spotImg} className="icon"></img>
                <div className="drzac-imena">
                  <p className="tekst3">Aleksandra Medvedeva 14</p>
                  <p className="tekst2">18 000 Niš, Srbija</p>
                </div>
              </div>
            </div>
            <div className="card">
              <p className="card-naslov">Kontakt:</p>
              <div className="card-linija"></div>
              <div className="lista-brojeva">
                <div className="drzac-slike-i-imena">
                  <img alt="spot" src={phoneImg} className="icon"></img>
                  <div className="drzac-imena">
                    <p className="tekst3">
                      Kristina Vučković - Predsednica organizacije
                    </p>
                    <p className="tekst2">+381 60 0588 535</p>
                    <p className="tekst2">kristina.vuckovic@sicef.info</p>
                  </div>
                </div>
                <div className="drzac-slike-i-imena">
                  <img alt="spot" src={phoneImg} className="icon"></img>
                  <div className="drzac-imena">
                    <p className="tekst3">Marko Stojadinović - ER</p>
                    <p className="tekst2">+381 61 6671 633</p>
                    <p className="tekst2">marko.stojadinovic@sicef.info</p>
                  </div>
                </div>
                <div className="drzac-slike-i-imena">
                  <img alt="spot" src={phoneImg} className="icon"></img>
                  <div className="drzac-imena">
                    <p className="tekst3">
                      Mila Cvetković - Koordinatorka događaja
                    </p>
                    <p className="tekst2">+381 62 8308 724</p>
                    <p className="tekst2">mila.cvetkovic@sicef.info</p>
                  </div>
                </div>
                {/*<div className="drzac-slike-i-imena">
                                    <img alt="spot" src={require("../../assets/phone.png")} className="icon"></img>
                                    <div className="drzac-imena">
                                        <p className="tekst3">Filip Nikolić - koordinator</p>
                                        <p className="tekst2">+381 62 8434 401</p>
                                        <p className="tekst2">filip.nikolic@sicef.info</p>
                                    </div>
                                </div>*/}
              </div>
            </div>
            <div className="card">
              <p className="card-naslov">Poruka:</p>
              <div className="card-linija"></div>
              <li className="lista">
                <img alt="spot" src={mailImg} className="icon"></img>
                <p className="tekst">office@sicef.info</p>
              </li>
            </div>
          </div>

          <div className="footer-area-copyright">
            <p className="copyright-tekst">
              Copyright 2023 © SICEF. All Rights Reserved
            </p>
            <img alt="logo" src={sicefLogo} className="sicef-logo"></img>
            {/*<div className="sponzori">
                            <img alt="logo" src={ntpLogo} className="sponzori-logo"></img>
                            <img alt="logo" src={elfakLogo} className="sponzori-logo"></img>
                            <img alt="logo" src={rcmtLogo} className="sponzori-logo"></img>
                            <img alt="logo" src={diligentLogo} className="sponzori-logo"></img>
                            <img alt="logo" src={BCLogo} className="sponzori-logo"></img>
                            <img alt="logo" src={IngLogo} className="sponzori-logo"></img>
                            <img alt="logo" src={MHGLogo} className="sponzori-logo"></img>
                            <img alt="logo" src={nlhLogo} className="sponzori-logo"></img>
                            <img alt="logo" src={productHiveLogo} className="sponzori-logo"></img>
                        </div>*/}
          </div>
        </div>
      </div>
    )
  }
}

export default FooterArea
