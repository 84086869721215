import React from "react";
import "../../style/agenda/agenda-day.scss";

const AgendaDayTwo = (props) => (
  <div className="agenda-day">
    <h1>Software engineering</h1>
    <h2>13:00</h2>
    <p>Svečano otvaranje drugog dana</p>
    <h2>13:10</h2>
    <p>SEO Optimizacija <br></br> Nikola Zlatkov, Front-End developer @ DualSOFT</p>
    <h2>14:00</h2>
    <p>Big picture of DevOps <br></br> Bratislav Vukadinović, Technical Lead @ Seavus IT Edukativni Centar</p>
    <h2>14:50</h2>
    <p>Pauza</p>
    <h2>15:40</h2>
    <p>Razvoj softvera potpomognut veštačkom inteligencijom <br></br> Prof. dr Aleksandar Milosavljević @ Elektronski Fakultet u Nišu</p>
    <h2>16:30</h2>
    <p>Druga strana IT-a <br></br>Teodora Gavrilović</p>
    <h2>17:20</h2>
    <p>Svečano zatvaranje događaja</p>
  </div>
);

export default AgendaDayTwo;
